import { Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppInterface } from '../../../interfaces/app.interface';
import { AppInstanceService } from '../../../services/appinstance-service/appinstance.service';
import { AddAppToFolderDialogComponent } from '../add-app-to-folder-dialog/add-app-to-folder-dialog.component';
import { ToastMessageService } from 'src/app/pages/app-finder-page/services/toast-message-service/toast-message.service';
import { ToastMessageInterface } from 'src/app/core/interfaces/toast-message.interface';
import { WidgetUIService } from '../../../../pages/start-page/services/widget-ui.service';
import { AddRemoveState } from '../../../enums/add-remove-state.enum';

@Component({
  selector: 'add-remove-app-button',
  imports: [CommonModule, AddAppToFolderDialogComponent],
  templateUrl: './add-remove-app-button.component.html',
  styleUrls: ['./add-remove-app-button.component.scss']
})
export class AddRemoveAppButtonComponent {
  @Input({ required: true }) app!: AppInterface;
  @Input() recommendSectionName?: string;
  @Input() isFromDetailPage: boolean = false;
  @Input() recommendedSectionIdentification: string = '';
  protected showAddAppDialog: boolean = false;
  protected readonly AddRemoveState = AddRemoveState;
  @ViewChild('addAppToFolderDialog')
  private addAppToFolderDialog!: AddAppToFolderDialogComponent;

  constructor(
    private appInstanceService: AppInstanceService,
    private toastMessageService: ToastMessageService,
    private widgetUIService: WidgetUIService
  ) {
  }

  addAppToFolder(): void {
    this.addAppToFolderDialog.openAddAppToFolderDialog();
  }

  removeAppInstance(): void {
    const message: ToastMessageInterface = {
      primaryMessage: this.app.title,
      secondMessage: ` has been removed from your Start Page.`
    };
    this.appInstanceService.deleteAppInstance(this.app).subscribe(() => {
      this.app.inDashboard = false;
      this.app.appInstanceId = undefined;
      this.toastMessageService.openToastDialog(message);
      /*
       * DXFINSIDEPORTAL-2806
       * Retrieve the app's representation from `WidgetUIService` to ensure consistency across the app state.
       * If the app is found in the `WidgetUIService` state, update its properties to mirror the changes.
       * `forceAllAppsSignalRecomputation` in `WidgetUIService` to ensure all reactive properties that depend on the
       * apps list are updated, triggering the UI to refresh.
       */
      const appFromWidgetUIService = this.widgetUIService
        .apps()
        .find((_app) => _app.appId == this.app.appId);
      if (appFromWidgetUIService) {
        appFromWidgetUIService.inDashboard = false;
        appFromWidgetUIService.appInstanceId = undefined;
      }
      this.widgetUIService.forceAllAppsSignalRecomputation();
    });
  }

  onHoverOrTouch(): void {
    this.showAddAppDialog = true;
  }

  protected getState(): AddRemoveState {
    return this.app.inDashboard ? AddRemoveState.REMOVE : AddRemoveState.ADD;
  }
}
