<article
  (touchstart)="onHoverOrTouch()"
  (mouseover)="onHoverOrTouch()"
  #buttonContainer
  class="add-remove-button-container"
>
  @if (getState() === AddRemoveState.ADD) {
    <button (click)="addAppToFolder()" class="add">
      <i class="boschicon-bosch-ic-add-frame"></i>
      Add
    </button>
  } @else if (getState() === AddRemoveState.REMOVE) {
    <button (click)="removeAppInstance()" class="remove">Remove</button>
  }
</article>

@defer (when showAddAppDialog) {
  <add-app-to-folder-dialog
    [isFromDetailPage]="isFromDetailPage"
    [recommendedSectionIdentification]="recommendedSectionIdentification"
    [app]="app"
    #addAppToFolderDialog
  />
}
